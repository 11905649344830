import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {Router, RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {IonicStorageModule} from '@ionic/storage-angular';
import {Drivers} from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateCompiler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SharedModule} from './shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {MomentModule} from 'ngx-moment';
import {OnboardingModule} from './modals/onboarding/onboarding.module';
import {
    MESSAGE_FORMAT_CONFIG,
    TranslateMessageFormatCompiler,
    TranslateMessageFormatDebugCompiler
} from 'ngx-translate-messageformat-compiler';
import {environment} from '../environments/environment';
import * as SentrySibling from '@sentry/angular-ivy';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            mode: 'ios',
            rippleEffect: false,
            loadingSpinner: 'crescent'
        }),
        IonicStorageModule.forRoot({
            name: '_myDb',
            driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
        }),
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: environment.debugTranslations ? TranslateMessageFormatDebugCompiler : TranslateMessageFormatCompiler
            }
        }),
        SharedModule,
        MomentModule,
        OnboardingModule
    ],
    providers: [
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {
            provide: MESSAGE_FORMAT_CONFIG,
            useValue: { locales: ['en', 'fr'] }
        },
        {
            provide: ErrorHandler,
            useValue: SentrySibling.createErrorHandler(),
        },
        {
            provide: SentrySibling.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [SentrySibling.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
