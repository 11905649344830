import {Component, OnInit} from '@angular/core';
import {AlertController, LoadingController, NavController, Platform} from '@ionic/angular';
import {EventService} from './services/event.service';
import {ActivatedRoute, NavigationStart, Params, Router} from '@angular/router';
import {Storage} from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {SplashScreen} from '@capacitor/splash-screen';
import Swiper, {Pagination} from 'swiper';
import {AuthService} from './services/auth.service';
import {UserService} from './services/user.service';
import {PushNotificationsService} from './services/push-notifications.service';
import {AppUpdateService} from './services/app-update.service';
import {SkillService} from './services/skill.service';
import {TranslateService} from './services/translate.service';
import {AuthData} from './models/auth-status';
import {UpdateService} from './services/update.service';
import {SettingsService} from './services/settings.service';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import {DeepLinkingService} from './services/deep-linking.service';
import {filter, first} from 'rxjs';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import {ChallengeService} from "./services/challenge.service";

Swiper.use([Pagination]);

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    protected pages: any;
    protected authData: AuthData;
    protected canShowMenu = false;
    protected splashScreenHidden = false;

    ngOnInit() {
        this.checkExpiredBrowser();
    }

    constructor(
        private readonly appUpdateService: AppUpdateService,
        private readonly authService: AuthService,
        private readonly userService: UserService,
        private readonly pushNotificationsService: PushNotificationsService,
        private readonly platform: Platform,
        private readonly navCtrl: NavController,
        private readonly skillService: SkillService,
        private readonly translateService: TranslateService,
        private readonly events: EventService,
        private readonly storage: Storage,
        private readonly router: Router,
        private readonly updateService: UpdateService,
        private readonly settingsService: SettingsService,
        private readonly deepLinkingService: DeepLinkingService,
        private readonly loadingCtrl: LoadingController,
        private readonly alertCtrl: AlertController,
        private readonly challengeService: ChallengeService,
    ) {
        this.initializeApp();
    }

    async initializeApp() {
        this.platform.ready().then(async () => {

            // Init storage
            await this.storage.defineDriver(CordovaSQLiteDriver);
            await this.storage.create();
            // Init settings
            await this.settingsService.initSettings();
            this.setDarkMode();

            // Init translations
            await this.translateService.init();

            // Check app version
            if (this.platform.is('capacitor')) {
                this.checkAppVersion();
            }

            // Load app
            this.loadApp();
            this.subscribeToEvents();

            // Get API Credentials
            const url = new URL(window.location.href);
            this.authService.getAPICredentials().subscribe({
                next: async credentials => {
                    const path = url.pathname;
                    const saml = url.searchParams.get('saml');
                    if (path === '/' && saml !== null) {
                        const email = url.searchParams.get('email');
                        const token = url.searchParams.get('token');
                        if (saml === 'true') {
                            // Will be redirected to login, trigger the classic login with values
                            this.userService.triggerLogin.next({email, password: token});
                        } else {
                            // Display error : saml is not true
                            const alert = await this.alertCtrl.create({
                                header: this.translateService.get(_('login.TITLE_1')),
                                message: this.translateService.get(_('login.ERREUR_1')),
                                buttons: [this.translateService.get(_('OK'))]
                            });
                            alert.present();
                        }
                    }
                }
            });

            // Subscribe to auth status
            this.authService.authStatus.subscribe(status => {
                if (status?.authDone) {

                    this.authData = status?.authData;
                } else {
                    this.authData = null;
                }
            });
            this.updateService.updateStatus.subscribe(status => {
                this.canShowMenu = status;
            });

        });
    }

    getPendingCount() {
        const user = this.authService.authStatus.value?.authData;
        let pending = 0;
        if (user && user.data.feedbacks.received){
            for (const u of user.data.feedbacks.received) {
                pending += u.feedbacks.length;
            }
        }
        if (user && user.data.feedbacks.auto){
            const auto = user.data.feedbacks.auto.filter(
                (item) => item.status === 0
            );
            pending += auto.length;
        }
        if (user
            && user.data.uncompletedGrids
            && user.data.uncompletedGrids.length > 0
        ) {
            for (const g of user.data.uncompletedGrids){
                pending++;
            }
        }
        return pending;
    }

    getUnReadAnswer(): number {
        const user = this.authService.authStatus.value?.authData;
        return user?.data?.feedbacks?.unread_answers || 0;
    }

    getNewsfeedCount(): number {
        const user = this.authService.authStatus.value?.authData;
        const newsfeed: any[] = user?.data?.gamification?.newsfeed || [];
        return newsfeed.filter(nf => !nf.is_read).length;
    }

    loadApp() {
        this.authService.isLogged().then(logged => {
            if (this.platform.is('capacitor')) {
                // Register push notifications
                this.pushNotificationsService.registerToken();
                // Register push notifications tap event
                this.pushNotificationsService.notificationTapped();
                // Handle deep linking
                this.deepLinkingService.handleDeepLinking().then(() => {
                    // Register deep linking
                    this.deepLinkingService.registerDeepLinking();
                });
            }

            // User is not logged, let the router decide where to go
            if (!logged) {

                // We can hide the splash screen
                if (this.platform.is('capacitor') && !this.splashScreenHidden) {
                    SplashScreen.hide();
                    this.splashScreenHidden = true;
                }

                return;
            }

            // Update user info
            this.userService.updateUserInfo().subscribe({
                complete: () => {
                    // We can hide the splash screen
                    if (this.platform.is('capacitor') && !this.splashScreenHidden) {
                        SplashScreen.hide();
                        this.splashScreenHidden = true;
                    }
                }
            });

            // Refresh challenges
            this.challengeService.refreshChallenges().subscribe();

            // Log platform used
            const isBrowser = !this.platform.is('capacitor');
            this.userService.savePlatformUsed(isBrowser).subscribe();

        }, error => console.error('Error checking if user is logged', error));
    }

    private checkExpiredBrowser() {
        const sAgent = window.navigator.userAgent;
        const Idx = sAgent.indexOf('MSIE');
        let version;

        if (Idx > 0) {
            version = parseInt(sAgent.substring(Idx + 5, sAgent.indexOf('.', Idx)), 10);
        } else if (!!navigator.userAgent.match(/Trident\/7\./)) {
            version = 11;
        } else {
            version = 0;
        }
        if (version !== 0 && version !== undefined) {
            if (version <= 11) {
                alert(
                    'La version de votre navigateur n\'est pas à jour. Vous devez utiliser Microsoft Edge, Google Chrome ou Mozilla Firefox pour profiter des fonctionnalités de COSS par 5Feedback.'
                );
            }
        }
    }

    private setColorMode(darkMode: boolean) {

        // On html tag, add the dark-theme class
        document.querySelector('html').classList.toggle('dark-theme', darkMode);
    }

    private setDarkMode(): void {
        // Listen for changes
        this.settingsService.settingsChanged.subscribe((key: string) => {
            if (key === 'darkMode') {
                this.settingsService.getSettings('darkMode');
                this.setColorMode(this.settingsService.getSettings('darkMode'));
            }
        });

        // Check if preference exists
        const preference = this.settingsService.getSettings('darkMode');
        if (preference !== null) {
            // Return preference if it exists
            this.setColorMode(preference);
        } else {
            // Return preference if it exists
            const medias = window.matchMedia('(prefers-color-scheme: dark)');
            this.settingsService.setSettings('darkMode', medias.matches);
            this.setColorMode(medias.matches);
        }
    }

    private async checkAppVersion(): Promise<void> {
        return new Promise(resolve => {
            AppUpdate.getAppUpdateInfo().then( info => {
                let version = info.currentVersion;
                if (this.platform.is('android')) {
                    // Convert version code to semantic version
                    version = version.replace(/(\d{1,2})(\d{2})(\d{2})/g, '$1.$2.$3');
                }
                this.appUpdateService.checkUpdates(version).subscribe({
                    next: result => {
                        if (result === 'update') {
                            this.navCtrl.navigateRoot('/update');
                        }
                        resolve();
                    },
                    error: () => {
                        resolve();
                    }
                });
            }).catch( error => {
                resolve();
            });
        });
    }

    private subscribeToEvents() {
        // Event reçu de guest-answer
        this.events.subscribe('localeChanged', (locale) => {
            this.translateService.changeLocale(locale);
            this.userService.updateUserInfo().subscribe();
            this.skillService.getSkillGroups().subscribe();
        });

        this.events.subscribe('localeChangedServer', (locale: any) => {
            this.translateService.changeLocale(locale);
            // this.authService.updateUserInfo().subscribe();
            // this.skillService.updateSkills().subscribe();
        });

        // Event reçu de guest-answer
        this.events.subscribe('userDisconnect', (data: any) => {
            this.navCtrl.navigateRoot('/login');
            this.authService.logout();
        });
    }

    private async doLogin(email: string, token: string): Promise<void> {
        this.userService.triggerLogin.next({email, password: token});
    }
}
