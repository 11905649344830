import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {map} from 'rxjs/operators';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {environment} from '../../environments/environment';
import {Contact} from '../models/contact';
import {Skill} from '../models/skill';
import {Feedback, FeedbackAnswer, PostOnboardingFeedbacks, Recipient} from '../models/feedback';
import {Observable} from 'rxjs';
import {StorageService} from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {

    requestFeedback: {
        eventTitle: string;
        skillIds: number[],
        contacts: Contact[],
    } = {
        skillIds: null,
        contacts: [],
        eventTitle: null
    };

    constructor(
        private readonly apiService: ApiService,
        private readonly authService: AuthService,
        private readonly storage: StorageService,
        private readonly userService: UserService
    ) {
    }

    public getStatisticsUrl(): string {
        return environment.apiLoginEndpoint + '/api/user/statistics';
    }

    postFeedbackRequest(contacts: Contact[], skillId: number, eventTitle: string, forceRefresh = false) {
        const recipients = contacts.map(contact => ({
            firstname: contact.firstname,
            lastname: contact.lastname,
            email: contact.email,
            roles: contact.roles,
            type: Contact.findApiType(contact.type || null),
            title: contact.title || null
        }));
        this.incrementFeedbackSentCount();
        const body = {recipients, skill: skillId, eventTitle};
        return this.apiService.post('/api/feedbacks', body).pipe(map(
            response => {
                if (forceRefresh) {
                    this.userService.updateUserInfo().subscribe();
                }
                return true;
            }));
    }

    postOnboardingFeedbackRequest(contacts: Contact[], skills: number[]) {
        const recipients = contacts.map(contact => ({
            firstname: contact.firstname,
            lastname: contact.lastname,
            email: contact.email,
            roles: contact.roles,
            type: Contact.findApiType(contact.type || null),
            title: contact.title || null
        }));

        // Create the body
        const data: PostOnboardingFeedbacks = {
            recipients,
            eventTitle: null,
            skills
        };

        return this.apiService
            .post('/api/feedbacks/onboardings', data)
            .pipe(map(response => {
                this.userService.updateUserInfo().subscribe();
                return true;
            }));
    }

    postFeedbackAnswer(feedback: FeedbackAnswer, isGuest: boolean, recipient: Recipient, locale: string) {
        if (isGuest) {
            if (recipient) {
                feedback.firstname = recipient.firstname;
                feedback.lastname = recipient.lastname;
                feedback.email = recipient.email;
            }

            return this.apiService.patch(`/api/guest-answer/feedback/${locale}/${feedback.id}`, feedback);
        }

        return this.apiService
            .patch('/api/feedbacks/' + feedback.id, feedback)
            .pipe(map(response => {
                this.authService.authStatus.value.authData.data.feedbacks = response;
                this.userService.updateUserInfo().subscribe();
                // this.feedbacksAnswerItems = response.json();
                return response;
            }));
    }

    sendStatistics() {
        return this.apiService.get('/api/user/statistics/send').pipe(map(
            response => {
                // this.auth.updateUserInfo().subscribe();
                // this.feedbacksAnswerItems = response.json();
                // this.loading.dismissAll();
                // return response.json();

            },
            error => {
                // this.loading.dismissAll();

                // return false;
            }
        ));
    }

    ignoreFeedback(feedback: any, isGuest: boolean, locale: string) {
        const fb = {status: 2};
        if (isGuest){
            return this.apiService.patch(`/api/guest-answer/feedback/${locale}/${feedback.id}`, fb).pipe(map(
                response => {
                    return true;
                }));
        } else {
            return this.apiService
                .patch<any>('/api/feedbacks/' + feedback.id, fb)
                .pipe(map(response => {
                    this.userService.updateUserInfo().subscribe();
                    return response;
                }));
        }
    }

    postSharedFeedback(skill: { id: number }, eventTitle: string, type: 'link' | 'app') {
        const data = {skill: skill.id, eventTitle, type};
        return this.apiService
            .post<{ link: string }>('/api/sharedfeedbacks', data)
            .pipe(map(response => {
                return response;
            }));
    }

    getFeedbackGuestAnswer(feedbackId: number, token: string, locale: string) {
        return this.apiService.get<{
            feedback: Feedback,
            firstname: string,
            recipient: Recipient
            feedbacks: Feedback[]
        }>('/api/guest-answer/feedback/' + locale + '/' + feedbackId + '/' + token);
    }

    removeCoffeeRequest(feedback: any) {
        return this.apiService
            .patch<any>('/api/feedbacks/' + feedback.feedback + '/coffee', feedback)
            .pipe(map(
                response => {
                    const user = this.authService.authStatus.value.authData;
                    user.data.feedbacks.sent = user.data.feedbacks.sent.filter(item => item.feedback !== feedback.feedback);
                    this.userService.updateUserInfo().subscribe();
                    return response;
                }));
    }

    postRecurrentFeedbackRequest(contacts: Contact[], skillId: number) {
        const body = {
            recipients: contacts.map(contact => ({email: contact.email})),
            skill: {id: skillId}
        };
        return this.apiService
            .post('/api/recurrentfeedbacks', body)
            .pipe(map(response => {
                this.userService.updateUserInfo().subscribe();
                return true;
            }));
    }

    patchRecurrentFeedbacks(skill: { skillId: number }) {
        return this.apiService.patch('/api/recurrentfeedback', { skillId : skill.skillId });
    }

    postOnboardingRecurrentFeedbackRequest(recipients: Contact[], skills: Skill[]): Observable<boolean> {
        const body: any = {
            recipients: recipients.map(contact => ({
                firstname: contact.firstname,
                lastname: contact.lastname,
                email: contact.email,
                title: contact.title,
                type: Contact.findApiType(contact.type)
            })),
            skills: skills.map(skill => ({id: skill.id}))
        };

        return this.apiService.post('/api/recurrentfeedbacks/onboardings', body).pipe(map(
            response => {
                this.userService.updateUserInfo().subscribe();
                return true;
            })
        );
    }

    postGridAnswer(grid) {
        const body = {
            base_grid_questions: grid.base_grid.base_grid_questions
        };
        return this.apiService
            .post('/api/grids/' + grid.id, body)
            .pipe(map(response => {
                this.userService.updateUserInfo().subscribe();
                return response;
            }));
    }

    getGridGuestAnswer(gridId: number, token: string, locale: string) {
        return this.apiService.get('/api/guest-answer/grid/' + locale + '/' + gridId + '/' + token);
    }

    postGridGuestAnswer(grid) {
        const body = {
            base_grid_questions: grid.base_grid.base_grid_questions
        };
        return this.apiService.post('/api/guest-answer/grid/' + grid.id, body);
    }

    getDaysSinceLastFeedback(skill: Skill, contact: Contact): number {
        // For skill, find the last feedback sent to contact
        const feedbacks = this.authService.authStatus.value.authData.data.feedbacks.sent;
        const feedback = feedbacks.find(f => f.skill.id === skill.id && f.recipientEmail === contact.email);
        if (!feedback) {
            return null;
        }

        return 0;

        // const lastFeedbackDate = new Date(feedback.createdAt);
        // const today = new Date();
        // return Math.floor((today.getTime() - lastFeedbackDate.getTime()) / (1000 * 3600 * 24));
    }

    findPendingAutoEval(skillId: number): Feedback {
        // Check if we have an auto eval available for this skill
        const autoEvals = this.authService.authStatus.value.authData.data.feedbacks.auto;
        // Find the auto eval for this skill
        return autoEvals.find(a => a.skill.id === skillId && a.status === 0);
    }

    hasPendingAutoEval(): boolean {
        // Check if we have an auto eval available for this skill
        const autoEvals = this.authService.authStatus.value.authData.data.feedbacks.auto;
        // Find the auto eval for this skill
        return autoEvals.some(a => a.status === 0);
    }

    private async incrementFeedbackSentCount(): Promise<void> {
        // get count from storage
        let count = (await this.getFeedbackSentCount()) || 0;
        // reset count if more than 10
        if (count >= 10) {
            count = 0;
        }
        // increment count
        await this.storage.set('fb_sent_count', count + 1);
    }

    async getFeedbackSentCount(): Promise<number> {
        // get count from storage
        const count = this.storage.get('fb_sent_count');
        return count ? count : 0;
    }

    async askNotify(): Promise<boolean> {
        // check if user checked the "do not ask again" checkbox
        const doNotAsk = await this.storage.get('fb-dont-notify') === 'true';
        // get count of feedbacks sent
        const feedbacksSent = await this.getFeedbackSentCount();

        // always ask if more than 9 feedbacksSent. Otherwise, ask only if doNotAsk is false
        return feedbacksSent > 9 || !doNotAsk;
    }

    async doNotAskNotify(): Promise<void> {
        // set the "do not ask again" checkbox
        await this.storage.set('fb-dont-notify', 'true');
    }
}
