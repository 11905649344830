import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from '@sentry/capacitor';
import * as SentrySibling from '@sentry/angular-ivy';

if (environment.production) {
    enableProdMode();
    console.log = () => {};
}

Sentry.init({
        dsn: 'https://9ca5d621b76d52fa0f53a144e4a9639c@o4504710279528448.ingest.sentry.io/4506001518886912',
        enabled: environment.production,
        // To set your release and dist versions
        release: 'coss-edu-app@' + environment.version + '-build.' + environment.buildNumber,
        dist: '1',
        tracesSampleRate: 1.0,
        integrations: [
            new SentrySibling.BrowserTracing({
                tracePropagationTargets: ['localhost'],
                routingInstrumentation: SentrySibling.routingInstrumentation,
            }),
        ] as any,
    },
    // Forward the init method to the sibling Framework.
    SentrySibling.init
);

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
