export type ContactListType = 'pro' | 'external_pro' | 'school' | 'sport_asso';

export type ContactType = 'external_pro' | 'pro' | 'sport_asso' | 'other' | 'tutor' | 'school_referent' | 'school_admin' | 'teacher' | 'student';

export class Contact {
    id: number; // Only for source: 'api'
    firstname: string;
    lastname: string;
    email: string;
    checked: boolean;
    source: 'device' | 'api' | 'local';
    type: ContactType;
    status: boolean = undefined;
    title: string;
    roles: string[];
    lastContact: number; // Time of last contact

    constructor(data?: any) {
        if (data) {
            this.firstname = data.firstname;
            this.lastname = data.lastname;
            this.email = data.email;
            this.checked = data.checked;
            this.source = data.source;
            this.status = data.status;
            this.title = data.title;
            this.roles = data.roles || [];
            this.type = data.type;
        }
    }

    get daysBeforeAvailable(): number {
        // 90 days since last contact
        return 90 - Math.floor((Date.now() - this.lastContact) / (1000 * 60 * 60 * 24));
    }

    get contactedDaysAgo(): number {
        // Get how many days ago the contact was last contacted
        return Math.floor((Date.now() - this.lastContact) / (1000 * 60 * 60 * 24));
    }

    get icon(): string {
        switch (this.type) {
            case 'external_pro':
                return 'id-card-outline';
            case 'pro':
                return 'business-outline';
            case 'tutor':
                return 'people-outline';
            case 'sport_asso':
                return 'accessibility-outline';
            case 'school_referent':
            case 'school_admin':
            case 'teacher':
                return 'school-outline';
            default:
                return 'person-outline';
        }
    }

    get isValid(): boolean {
        if (['external_pro', 'pro', 'sport_asso'].includes(this.type)) {
            return this.status;
        }

        if (['tutor', 'school_referent', 'school_admin', 'teacher'].includes(this.type)) {
            return true;
        }

        return undefined;
    }

    get name(): string {
        return `${this.firstname} ${this.lastname}`;
    }

    static findApiType(type: ContactType): string {
        switch (type) {
            case 'pro':
                return 'RECIPIENT_PRO';
            case 'external_pro':
                return 'RECIPIENT_EXTERNAL_PRO';
            case 'sport_asso':
                return 'RECIPIENT_SPORT_ASSO';
            case 'other':
                return 'RECIPIENT_OTHER';
            default:
                return null;
        }
    }
}
