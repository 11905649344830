import {Component} from '@angular/core';
import {AlertController, LoadingController, ModalController, NavController} from '@ionic/angular';
import {FeedbackService} from '../../../services/feedback.service';
import {Contact} from '../../../models/contact';
import {ContactService} from '../../../services/contact.service';
import {TranslateService} from '../../../services/translate.service';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

@Component({
    selector: 'app-feedback-pick-evaluator',
    templateUrl: './feedback-pick-evaluator.page.html',
    styleUrls: ['./feedback-pick-evaluator.page.scss'],
})
export class FeedbackPickEvaluatorPage {

    selectedContacts: Contact[] = [];
    skillId: number;
    disabled = false;

    constructor(
        private readonly feedbackService: FeedbackService,
        private readonly navCtrl: NavController,
        private readonly modalCtrl: ModalController,
        private readonly alertCtrl: AlertController,
        private readonly translateService: TranslateService,
        private readonly contactService: ContactService,
        private readonly loadingCtrl: LoadingController,
    ) { }

    ionViewWillEnter() {
        this.skillId = this.feedbackService.requestFeedback.skillIds?.[0];
    }

    navigateBack() {
        this.navCtrl.back();
    }

    async submit() {
        // Check selected contacts
        const valid = await this.contactService.checkMinRequiredContacts(this.selectedContacts, this.skillId);
        if (!valid) {
            return;
        }
        this.disabled = true;
        const loader = await this.loadingCtrl.create();
        await loader.present();
        this.feedbackService.requestFeedback.contacts = this.selectedContacts;

        // Send the feedbacks requests
        this.feedbackService.postFeedbackRequest(
            this.feedbackService.requestFeedback.contacts,
            this.feedbackService.requestFeedback.skillIds[0],
            this.feedbackService.requestFeedback.eventTitle
        ).subscribe({
            next: () => {
                this.navCtrl.navigateForward('/feedbacks/request/confirmation');
                loader.dismiss();
            },
            error: async () => {
                this.disabled = false;
                const alert = await this.alertCtrl.create({
                    header: this.translateService.get(_('ERREUR_PRODUITE')),
                    buttons: [this.translateService.get(_('OK'))],
                });
                await alert.present();
                loader.dismiss();
            }
        });
    }
}
